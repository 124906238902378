import styles from './style.module.css';

const AutoPlay = () => {
  return (
    <div className={styles.marquee}>
      <div className={styles.propertyStart}>
        <div className={styles.logoWrapper}>
          <b>DESCI</b>
          <img alt="Desci Seoul" src="/images/autoplay/party.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>DATA PROTOCOL</b>
          <img alt="Desci Seoul" src="/images/autoplay/summertime-sadness.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>AI</b>
          <img alt="Desci Seoul" src="/images/autoplay/stairs.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>BIO DAO</b>
          <img alt="Desci Seoul" src="/images/autoplay/arrow.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>INFRASTRUCTURE</b>
          <img alt="Desci Seoul" src="/images/autoplay/soft-flower.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>PERMISSIONLESS RESEARCH</b>
          <img alt="Desci Seoul" src="/images/autoplay/rainbow.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>OPEN SCIENCE</b>
          <img alt="Desci Seoul" src="/images/autoplay/rainbow2.svg" />
        </div>
        {/* mroe */}
        <div className={styles.logoWrapper}>
          <b>DESCI</b>
          <img alt="Desci Seoul" src="/images/autoplay/party.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>DATA PROTOCOL</b>
          <img alt="Desci Seoul" src="/images/autoplay/summertime-sadness.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>AI</b>
          <img alt="Desci Seoul" src="/images/autoplay/stairs.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>BIO DAO</b>
          <img alt="Desci Seoul" src="/images/autoplay/arrow.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>INFRASTRUCTURE</b>
          <img alt="Desci Seoul" src="/images/autoplay/soft-flower.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>PERMISSIONLESS RESEARCH</b>
          <img alt="Desci Seoul" src="/images/autoplay/rainbow.svg" />
        </div>
        <div className={styles.logoWrapper}>
          <b>OPEN SCIENCE</b>
          <img alt="Desci Seoul" src="/images/autoplay/rainbow2.svg" />
        </div>
      </div>
    </div>
  );
};

export default AutoPlay;
