import React, { useState, useEffect, useRef } from 'react';
import styles from './home.module.scss';
import AutoPlay from '../../components/AutoPlay';
import AutoPlayMobile from '../../components/AutoPlayMobile';

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isMenu, setIsMenu] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isVideo2Playing, setIsVideo2Playing] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isJoinHovered, setIsJoinHovered] = useState(false);
  const [isBecomeHovered, setIsBecomeHovered] = useState(false);
  const [isGetHovered, setIsGetHovered] = useState(false);
  const [isSonsorHovered, setIsSonsorHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isXHovered, setIsXHovered] = useState(false);
  const [isRegisterHovered, setIsRegisterHovered] = useState(false);

  useEffect(() => {
    if (isVideoPlaying && videoRef.current) {
      videoRef?.current?.play();
    }
  }, [isVideoPlaying]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    const handleScroll = () => {
      if (!isScrolling) {
        setIsScrolling(true);
      }

      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  return (
    <section className={styles.rootSection}>
      <section className={styles.homeSection}>
        {!isMobile ? (
          <>
            <section className={styles.menuSection}>
              <div className={styles.menuBox}>
                <a href="/" onMouseEnter={() => setIsScrolling(true)} onMouseLeave={() => setIsScrolling(false)}>
                  <img
                    className={`${styles.menuLogo} ${isScrolling ? styles.menuLogoHidden : ''}`}
                    alt="Desci Seoul"
                    src="/images/menu-logo.svg"
                  />
                  <img
                    className={`${styles.menuLogo} ${!isScrolling ? styles.menuLogoHidden : ''}`}
                    alt="Desci Seoul"
                    src="/images/menu-text.png"
                  />
                </a>
                <div className={styles.menuItemWrapper}>
                  <a href="#about">
                    <b className={styles.menuItem}>About</b>
                  </a>
                  <a href="#speakers">
                    <b className={styles.menuItem}>Speakers</b>
                  </a>
                  <a href="#sponsors">
                    <b className={styles.menuItem}>Sponsors</b>
                  </a>
                  <a href="#contact">
                    <b className={styles.menuItem}>Contact</b>
                  </a>
                </div>
              </div>
            </section>
            <section className={styles.mainSection}>
              <div className={styles.mainWrapper}>
                <img className={styles.vectorIcon} alt="Desci Seoul" src="/images/main-vector.svg" />
                <img className={styles.texture} alt="Desci Seoul" src="/images/main-texture.png" />
                <img className={styles.texture2} alt="Desci Seoul" src="/images/main-texture.png" />
                <img className={styles.texture3} alt="Desci Seoul" src="/images/main-texture.png" />
                <img className={styles.texture4} alt="Desci Seoul" src="/images/main-texture.png" />
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    EXPANDING <img alt="decsi kr" src="/images/main-asterisk.svg" />
                    <br />
                    THE DESCI LANDSCAPE
                  </div>
                  <div className={styles.date}>02 September 2024</div>
                </div>
                <div className={styles.buttonWrapper}>
                  <div className={styles.ticketBtn}>
                    <a
                      href="https://believed-tango-deb.notion.site/DeSci-Seoul-Agenda-0395c560ba18401b81b58f9eca31e4b1"
                      target="_blank"
                      onMouseEnter={() => setIsBecomeHovered(true)}
                      onMouseLeave={() => setIsBecomeHovered(false)}
                    >
                      <img
                        alt="Desci Seoul"
                        src={isBecomeHovered ? '/images/main-agenda-active.svg' : '/images/main-agenda.svg'}
                      />
                      <b>{`Agenda >`}</b>
                    </a>
                  </div>
                  <div className={styles.ticketBtn2}>
                    <a
                      href="https://lu.ma/73ak22pr"
                      target="_blank"
                      onMouseEnter={() => setIsGetHovered(true)}
                      onMouseLeave={() => setIsGetHovered(false)}
                    >
                      <img
                        alt="Desci Seoul"
                        src={isGetHovered ? '/images/main-ticket-active.svg' : '/images/main-ticket.svg'}
                      />
                      <b>{`Get Tickets >`}</b>
                    </a>
                  </div>
                  <div className={styles.ticketBtn3}>
                    <a
                      href="https://lu.ma/edn5l36a"
                      target="_blank"
                      onMouseEnter={() => setIsJoinHovered(true)}
                      onMouseLeave={() => setIsJoinHovered(false)}
                    >
                      <img
                        alt="Desci Seoul"
                        src={isJoinHovered ? '/images/register-vector.svg' : '/images/register-vector-active.svg'}
                      />
                      <b>{`Join Afterparty >`}</b>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.envisioningSection} id="about">
              <div className={styles.title}>Envisioning DeSci 2.0</div>
              <div className={styles.subTitle}>
                DeSci Seoul is a one-day conference focused on demonstrating how blockchain technology can revolutionize
                the way scientific research is conducted, shared, and funded.
              </div>
              <img className={styles.envisioningImg} alt="Desci Seoul" src="/images/envision-img.png" />
              <div></div>
              <img className={styles.explosionIcon} alt="Desci Seoul" src="/images/envision-explosion.svg" />
              <img className={styles.explosionIcon1} alt="Desci Seoul" src="/images/envision-explosion1.svg" />
              <div className={styles.subDec}>
                Through engaging presentations, insightful panel discussions, and hands-on workshops, the conference
                will highlight the milestones achieved, present visionary ideas for the next phase, and expand the DeSci
                narrative by exploring new possibilities and applications.
              </div>
              <div className={styles.explosionVideo}>
                <div className={styles.videoContainer}>
                  {!isVideoPlaying ? (
                    <a
                      className={styles.thumbnailContainer}
                      href="javascript:void(0)"
                      onClick={() => setIsVideoPlaying(true)}
                    >
                      <img alt="Desci Seoul" src={'/images/explosion-thumbnail.png'} />
                      <div className={styles.playButton}>{`let’s play ->`}</div>
                    </a>
                  ) : (
                    <div>
                      <video controls ref={videoRef}>
                        <source src="/explosion-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.logoAnimation}>
                <AutoPlay />
              </div>
            </section>
            <section className={styles.conferenceSection}>
              <div className={styles.title}>
                CONFERENCE
                <br />
                VENUE
              </div>
              <div className={styles.conferenceVideo}>
                <div className={styles.videoContainer}>
                  {!isVideo2Playing ? (
                    <a
                      className={styles.thumbnailContainer}
                      href="javascript:void(0)"
                      onClick={() => setIsVideo2Playing(true)}
                    >
                      <img src="/images/conference-thumbnail.png" alt="Custom Thumbnail" className={styles.thumbnail} />
                      <div className={styles.playButton}>{`let’s play ->`}</div>
                    </a>
                  ) : (
                    <iframe
                      key={'conferenceVideo'}
                      src="https://www.youtube.com/embed/Ql4UU2Dyxmo?autoplay=1&mute=1&modestbranding=1"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
              <img className={styles.conferenceImg} alt="Desci Seoul" src="/images/conference-img.png" />
              <img className={styles.conferenceMap} alt="Desci Seoul" src="/images/conference-map.png" />
              <img className={styles.conferenceVector} alt="Desci Seoul" src="/images/conference-vector.svg" />
              <div className={styles.logoAnimation}>
                <AutoPlay />
              </div>
            </section>
            <section className={styles.networkingSection}>
              <div className={styles.title}>
                NETWORKING
                <br />
                EVENTS
              </div>
              <img className={styles.networkingImg} alt="Desci Seoul" src="/images/networking-img.png" />
            </section>
            <section className={styles.speakersSection} id="speakers">
              <div className={styles.title}>Speakers</div>
              <div className={styles.speakersWrapper}>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-ethan.png" />
                  <div className={styles.speakerName}>Ethan Chae</div>
                  <div className={styles.speakerPosition}>
                    Co-CEO
                    <br />
                    of Hippocrat
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-goldoni.png" />
                  <div className={styles.speakerName}>Riccardo Goldoni</div>
                  <div className={styles.speakerPosition}>
                    Director
                    <br />
                    of ResearchHub Foundation
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-dan.png" />
                  <div className={styles.speakerName}>Dan Albert</div>
                  <div className={styles.speakerPosition}>
                    Director
                    <br />
                    of Solana Foundation
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-kwon.png" />
                  <div className={styles.speakerName2}>
                    Soon-Yong Kwon<span className={styles.subName}>M.D. Ph.D.</span>
                  </div>
                  <div className={styles.speakerPosition2}>
                    Honorary President of the Korean <br />
                    Association of Digital Health/
                    <br />
                    Ex. president of Eunpyeong St. Mary’s <br />
                    Hospital, Seoul,Korea
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-edison.png" />
                  <div className={styles.speakerName}>Edison Chen</div>
                  <div className={styles.speakerPosition}>
                    CEO
                    <br />
                    of CUDIS
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jelani.png" />
                  <div className={styles.speakerName}>Jelani Clarke</div>
                  <div className={styles.speakerPosition}>
                    Chief Business Development <br />
                    Officer
                    <br />
                    of AminoChain
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-panusith.png" />
                  <div className={styles.speakerName} style={{ letterSpacing: -0.3 }}>
                    Panusith Chomanan
                  </div>
                  <div className={styles.speakerPosition}>
                    Founder and CEO
                    <br />
                    of Ever Medical Technology
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-shellie.png" />
                  <div className={styles.speakerName}>Shellie Boudreau</div>
                  <div className={styles.speakerPosition}>
                    Head of Scientific
                    <br />
                    Communications
                    <br />
                    of NobleBlocks
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-andrew.png" />
                  <div className={styles.speakerName}>Andrew Law</div>
                  <div className={styles.speakerPosition}>
                    Head of APAC Growth
                    <br />
                    of IoTeX
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-seoung.png" />
                  <div className={styles.speakerName}>Seoung-uk Ha</div>
                  <div className={styles.speakerPosition}>
                    Director of Saluscare
                    <br />
                    Ex. Korea Medical Institute <br />
                    Central Medical Information
                    <br />
                    Center Executive
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-summer.png" />
                  <div className={styles.speakerName}>Summer Park</div>
                  <div className={styles.speakerPosition}>
                    Venture Partner
                    <br />
                    of Simsan Ventures
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-moyed.png" />
                  <div className={styles.speakerName}>Moyed Eun</div>
                  <div className={styles.speakerPosition}>
                    Independent Researcher
                    <br />
                    Ex. Co-Founder of Four
                    <br />
                    Pillars
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jay.png" />
                  <div className={styles.speakerName}>Jay Gu</div>
                  <div className={styles.speakerPosition}>
                    Strategic Planning Dept. <br />
                    Team Leader
                    <br />
                    of GC Holdings
                    <br />
                    Ex. President of ROKIT
                    <br />
                    Healthcare
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-aldo.png" />
                  <div className={styles.speakerName}>Aldo De Pape</div>
                  <div className={styles.speakerPosition}>
                    Co-Founder and CEO
                    <br />
                    of Genomes.io
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jing.png" />
                  <div className={styles.speakerName}>Jing</div>
                  <div className={styles.speakerPosition}>
                    Ecosystem
                    <br />
                    of Monad
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-john.png" />
                  <div className={styles.speakerName}>John Vibes</div>
                  <div className={styles.speakerPosition}>
                    Content Lead
                    <br />
                    of Somnia
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-yan.png" />
                  <div className={styles.speakerName}>Yan Bo Ng</div>
                  <div className={styles.speakerPosition}>
                    Enterprise Solutions <br />
                    Architect
                    <br />
                    of Filecoin Foundation
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-raza.png" />
                  <div className={styles.speakerName}>Raza</div>
                  <div className={styles.speakerPosition}>
                    Head of Growth
                    <br />
                    of Scroll
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jee.png" />
                  <div className={styles.speakerName}>Jee Sun Lee</div>
                  <div className={styles.speakerPosition}>
                    Head of Clinical <br />
                    Development Center <br />
                    of Daewoong Pharmaceutical <br />
                    Co.
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-mark.png" />
                  <div className={styles.speakerName}>Mark Rydon</div>
                  <div className={styles.speakerPosition}>
                    Co-Founder
                    <br />
                    of Aethir
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-kang.png" />
                  <div className={styles.speakerName}>Michelle Kang</div>
                  <div className={styles.speakerPosition}>
                    CMO
                    <br />
                    of Somnia
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-reffo.png" />
                  <div className={styles.speakerName}>Reffo</div>
                  <div className={styles.speakerPosition}>
                    CEO
                    <br />
                    of Puffpaw
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-sinhae.png" />
                  <div className={styles.speakerName}>Sinhae Lee</div>
                  <div className={styles.speakerPosition}>
                    Partner
                    <br />
                    of GBIC
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-iann.png" />
                  <div className={styles.speakerName}>Iann de Lapeyriere</div>
                  <div className={styles.speakerPosition}>
                    Associate
                    <br />
                    of Saxon
                  </div>
                </div>
              </div>
              <img className={styles.speakersImg} alt="Desci Seoul" src="/images/speakers-img.png" />
            </section>
            <section className={styles.broughtSection} id="sponsors">
              <div className={styles.title}>
                BROUGHT TO
                <br />
                YOU BY
              </div>
              <div className={styles.logoWrapper}>
                <img alt="Desci Seoul" src="/images/brought-logo.png" />
              </div>
            </section>
            <section className={styles.supportersSection}>
              <div className={styles.title}>Partners</div>
              <div className={styles.logoWrapper}>
                <img alt="Desci Seoul" src="/images/partners-logo.png" />
              </div>
            </section>
            <section className={styles.contactSection} id="contact">
              <div className={styles.contactWrapper}>
                <img className={styles.vectorIcon} alt="Desci Seoul" src="/images/contact-vector.svg" />
                <img className={styles.texture} alt="Desci Seoul" src="/images/main-texture.png" />
                <img className={styles.texture2} alt="Desci Seoul" src="/images/main-texture.png" />
                <div className={styles.contactBg}></div>
                <div className={styles.title}>
                  Contact
                  <br />
                  US
                </div>
                <div className={styles.buttonWrapper}>
                  <div className={styles.ticketBtn}>
                    <a
                      href="mailto:desciseoul@gmail.com?subject=Desci Seoul."
                      target="_blank"
                      onMouseEnter={() => setIsEmailHovered(true)}
                      onMouseLeave={() => setIsEmailHovered(false)}
                    >
                      <img
                        alt="Desci Seoul"
                        src={isEmailHovered ? '/images/contact-email-active.svg' : '/images/contact-email.svg'}
                      />
                      <b>{`E-mail >`}</b>
                    </a>
                  </div>
                  <div className={styles.ticketBtn}>
                    <a
                      href="https://twitter.com/DesciSeoul"
                      target="_blank"
                      onMouseEnter={() => setIsXHovered(true)}
                      onMouseLeave={() => setIsXHovered(false)}
                    >
                      <img
                        alt="Desci Seoul"
                        src={isXHovered ? '/images/contact-x-active.svg' : '/images/contact-x.svg'}
                      />
                      <b>{`X(twitter) >`}</b>
                    </a>
                  </div>
                </div>
              </div>
              <img className={styles.qrIcon} alt="Desci Seoul" src="/images/qr.svg" />
            </section>
            <section className={styles.logoAnimationSection}>
              <div className={styles.logoAnimation}>
                <AutoPlay />
              </div>
            </section>
            <section className={styles.registerSection}>
              <div className={styles.title}>
                Register
                <br />
                Now!
              </div>
              <div className={styles.buttonWrapper}>
                <div className={styles.ticketBtn}>
                  <a
                    href="https://lu.ma/73ak22pr"
                    target="_blank"
                    onMouseEnter={() => setIsRegisterHovered(true)}
                    onMouseLeave={() => setIsRegisterHovered(false)}
                  >
                    <img
                      alt="Desci Seoul"
                      src={isRegisterHovered ? '/images/register-vector-active.svg' : '/images/register-vector.svg'}
                    />
                    <b>{`get tickets >`}</b>
                  </a>
                </div>
              </div>
            </section>
            <section className={styles.footerSection}>
              <div className={styles.footerWrapper}>
                <div className={styles.title}>ⓒ DeSci Seoul 2024. All Rights Reserved.</div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className={styles.menuSection_m}>
              <div className={styles.menuBox}>
                <a href="/" onMouseEnter={() => setIsScrolling(true)} onMouseLeave={() => setIsScrolling(false)}>
                  <img
                    className={`${styles.menuLogo} ${isScrolling ? styles.menuLogoHidden : ''}`}
                    alt="Desci Seoul"
                    src="/images/mobile/menu-logo.svg"
                  />
                  <img
                    className={`${styles.menuLogo} ${!isScrolling ? styles.menuLogoHidden : ''}`}
                    alt="Desci Seoul"
                    src="/images/menu-text.png"
                  />
                </a>
                <div className={styles.menuIconWrapper}>
                  <div onClick={() => setIsMenu(!isMenu)}>
                    <img
                      className={`${styles.menuIcon} ${isMenu ? styles.menuIconHidden : ''}`}
                      alt="Desci Seoul"
                      src="/images/mobile/menu-icon.svg"
                    />
                    <img
                      className={`${styles.menuIcon} ${!isMenu ? styles.menuIconHidden : ''}`}
                      alt="Desci Seoul"
                      src="/images/mobile/menu-active.svg"
                    />
                  </div>
                </div>
                <div className={`${styles.menuItemWrapper} ${isMenu ? styles.menuOpen : styles.menuClosed}`}>
                  <a href="#about">
                    <b className={styles.menuItem}>About</b>
                  </a>
                  <a href="#speakers">
                    <b className={styles.menuItem}>Speakers</b>
                  </a>
                  <a href="#sponsors">
                    <b className={styles.menuItem}>Sponsors</b>
                  </a>
                  <a href="#contact">
                    <b className={styles.menuItem}>Contact</b>
                  </a>
                </div>
              </div>
            </section>
            <section className={styles.mainSection_m}>
              <img className={styles.vectorIcon} alt="Desci Seoul" src="/images/mobile/main-vector.svg" />
              <img className={styles.texture} alt="Desci Seoul" src="/images/mobile/main-texture.png" />
              <img className={styles.texture2} alt="Desci Seoul" src="/images/mobile/main-texture.png" />
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <img alt="decsi kr" src="/images/mobile/main-asterisk.svg" />
                  <br />
                  EXPANDING
                  <br />
                  THE DESCI
                  <br />
                  LANDSCAPE
                </div>
                <div className={styles.date}>02 September 2024</div>
              </div>
              <div className={styles.buttonWrapper}>
                <div className={styles.ticketBtn}>
                  <img alt="Desci Seoul" src="/images/mobile/main-agenda.svg" />

                  <a
                    href="https://believed-tango-deb.notion.site/DeSci-Seoul-Agenda-0395c560ba18401b81b58f9eca31e4b1"
                    target="_blank"
                  >
                    <b>{`Agenda >`}</b>
                  </a>
                </div>
                <div className={styles.ticketBtn2}>
                  <img alt="Desci Seoul" src="/images/mobile/main-ticket.svg" />
                  <a href="https://lu.ma/73ak22pr" target="_blank">
                    <b>{`Get Tickets >`}</b>
                  </a>
                </div>
              </div>
              <div className={styles.buttonWrapper2}>
                <div className={styles.ticketBtn3}>
                  <img alt="Desci Seoul" src="/images/mobile/main-join.svg" />
                  <a href="https://lu.ma/edn5l36a" target="_blank">
                    <b>{`Join Afterparty >`}</b>
                  </a>
                </div>
              </div>
            </section>
            <section className={styles.envisioningSection_m} id="about">
              <div className={styles.title}>
                Envisioning
                <br />
                DeSci 2.0
              </div>
              <div className={styles.subTitle}>
                DeSci Seoul is a one-day conference focused on demonstrating how blockchain technology can revolutionize
                the way scientific research is conducted, shared, and funded.
              </div>
              <img className={styles.envisioningImg} alt="Desci Seoul" src="/images/mobile/envision-img.png" />
              <div></div>
              <img className={styles.explosionIcon} alt="Desci Seoul" src="/images/mobile/envision-explosion.svg" />
              <img className={styles.explosionIcon1} alt="Desci Seoul" src="/images/mobile/envision-explosion1.svg" />
              <div className={styles.subDec}>
                Through engaging presentations, insightful panel discussions, and hands-on workshops, the conference
                will highlight the milestones achieved, present visionary ideas for the next phase, and expand the DeSci
                narrative by exploring new possibilities and applications.
              </div>
              <div className={styles.explosionVideo}>
                <div className={styles.videoContainer}>
                  {!isVideoPlaying ? (
                    <div className={styles.thumbnailContainer} onClick={() => setIsVideoPlaying(true)}>
                      <img src="/images/explosion-thumbnail.png" alt="Custom Thumbnail" className={styles.thumbnail} />
                      <div className={styles.playButton}>{`let’s play ->`}</div>
                    </div>
                  ) : (
                    <div>
                      <video controls ref={videoRef}>
                        <source src="/explosion-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.logoAnimation}>
                <AutoPlayMobile />
              </div>
            </section>
            <section className={styles.conferenceSection_m}>
              <div className={styles.title}>
                CONFERENCE
                <br />
                VENUE
              </div>
              <div className={styles.conferenceVideo}>
                <div className={styles.videoContainer}>
                  {!isVideo2Playing ? (
                    <div className={styles.thumbnailContainer} onClick={() => setIsVideo2Playing(true)}>
                      <img src="/images/conference-thumbnail.png" alt="Custom Thumbnail" className={styles.thumbnail} />
                      <div className={styles.playButton}>{`let’s play ->`}</div>
                    </div>
                  ) : (
                    <iframe
                      key={'conferenceVideo'}
                      src="https://www.youtube.com/embed/Ql4UU2Dyxmo?autoplay=1&mute=1&modestbranding=1"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
              <img className={styles.conferenceImg} alt="Desci Seoul" src="/images/mobile/conference-img1.png" />
              <img className={styles.conferenceImg} alt="Desci Seoul" src="/images/mobile/conference-img2.png" />
              <img className={styles.conferenceMap} alt="Desci Seoul" src="/images/mobile/conference-map.png" />
              <img className={styles.conferenceVector} alt="Desci Seoul" src="/images/conference-vector.svg" />
              <div className={styles.logoAnimation}>
                <AutoPlayMobile />
              </div>
            </section>
            <section className={styles.networkingSection_m}>
              <div className={styles.title}>
                NETWORKING
                <br />
                EVENTS
              </div>
              <img className={styles.networkingImg} alt="Desci Seoul" src="/images/mobile/networking-img.png" />
            </section>
            <section className={styles.speakersSection_m} id="speakers">
              <div className={styles.title}>Speakers</div>
              <div className={styles.speakersWrapper}>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-ethan.png" />
                  <div className={styles.speakerName}>Ethan Chae</div>
                  <div className={styles.speakerPosition}>
                    Co-CEO
                    <br />
                    of Hippocrat
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-goldoni.png" />
                  <div className={styles.speakerName}>Riccardo Goldoni</div>
                  <div className={styles.speakerPosition}>
                    Director
                    <br />
                    of ResearchHub Foundation
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-dan.png" />
                  <div className={styles.speakerName}>Dan Albert</div>
                  <div className={styles.speakerPosition}>
                    Director
                    <br />
                    of Solana Foundation
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-kwon.png" />
                  <div className={styles.speakerName}>
                    Soon-Yong Kwon<span className={styles.subName}>M.D. Ph.D.</span>
                  </div>
                  <div className={styles.speakerPosition2}>
                    Honorary President of the Korean <br />
                    Association of Digital Health/
                    <br />
                    Ex. president of Eunpyeong St. Mary’s <br />
                    Hospital, Seoul,Korea
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-edison.png" />
                  <div className={styles.speakerName}>Edison Chen</div>
                  <div className={styles.speakerPosition}>
                    CEO
                    <br />
                    of CUDIS
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jelani.png" />
                  <div className={styles.speakerName}>Jelani Clarke</div>
                  <div className={styles.speakerPosition}>
                    Chief Business Development <br />
                    Officer
                    <br />
                    of AminoChain
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-panusith.png" />
                  <div className={styles.speakerName} style={{ letterSpacing: -0.3 }}>
                    Panusith Chomanan
                  </div>
                  <div className={styles.speakerPosition}>
                    Founder and CEO
                    <br />
                    of Ever Medical Technology
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-shellie.png" />
                  <div className={styles.speakerName}>Shellie Boudreau</div>
                  <div className={styles.speakerPosition}>
                    Head of Scientific
                    <br />
                    Communications
                    <br />
                    of NobleBlocks
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-andrew.png" />
                  <div className={styles.speakerName}>Andrew Law</div>
                  <div className={styles.speakerPosition}>
                    Head of APAC Growth
                    <br />
                    of IoTeX
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-seoung.png" />
                  <div className={styles.speakerName}>Seoung-uk Ha</div>
                  <div className={styles.speakerPosition}>
                    Director of Saluscare
                    <br />
                    Ex. Korea Medical Institute <br />
                    Central Medical Information
                    <br />
                    Center Executive
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-summer.png" />
                  <div className={styles.speakerName}>Summer Park</div>
                  <div className={styles.speakerPosition}>
                    Venture Partner
                    <br />
                    of Simsan Ventures
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-moyed.png" />
                  <div className={styles.speakerName}>Moyed Eun</div>
                  <div className={styles.speakerPosition}>
                    Independent Researcher
                    <br />
                    Ex. Co-Founder of Four
                    <br />
                    Pillars
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jay.png" />
                  <div className={styles.speakerName}>Jay Gu</div>
                  <div className={styles.speakerPosition}>
                    Strategic Planning Dept. <br />
                    Team Leader
                    <br />
                    of GC Holdings
                    <br />
                    Ex. President of ROKIT
                    <br />
                    Healthcare
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-aldo.png" />
                  <div className={styles.speakerName}>Aldo De Pape</div>
                  <div className={styles.speakerPosition}>
                    Co-Founder and CEO
                    <br />
                    of Genomes.io
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jing.png" />
                  <div className={styles.speakerName}>Jing</div>
                  <div className={styles.speakerPosition}>
                    Ecosystem
                    <br />
                    of Monad
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-john.png" />
                  <div className={styles.speakerName}>John Vibes</div>
                  <div className={styles.speakerPosition}>
                    Content Lead
                    <br />
                    of Somnia
                  </div>
                </div>

                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-yan.png" />
                  <div className={styles.speakerName}>Yan Bo Ng</div>
                  <div className={styles.speakerPosition}>
                    Enterprise Solutions <br />
                    Architect
                    <br />
                    of Filecoin Foundation
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-raza.png" />
                  <div className={styles.speakerName}>Raza</div>
                  <div className={styles.speakerPosition}>
                    Head of Growth
                    <br />
                    of Scroll
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-jee.png" />
                  <div className={styles.speakerName}>Jee Sun Lee</div>
                  <div className={styles.speakerPosition}>
                    Head of Clinical <br />
                    Development Center <br />
                    of Daewoong Pharmaceutical <br />
                    Co.
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-mark.png" />
                  <div className={styles.speakerName}>Mark Rydon</div>
                  <div className={styles.speakerPosition}>
                    Co-Founder
                    <br />
                    of Aethir
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-kang.png" />
                  <div className={styles.speakerName}>Michelle Kang</div>
                  <div className={styles.speakerPosition}>
                    CMO
                    <br />
                    of Somnia
                  </div>
                </div>
                <div className={styles.speakerCard}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-reffo.png" />
                  <div className={styles.speakerName}>Reffo</div>
                  <div className={styles.speakerPosition}>
                    CEO
                    <br />
                    of Puffpaw
                  </div>
                </div>
                <div className={styles.speakerCard} style={{ marginRight: 8 }}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-sinhae.png" />
                  <div className={styles.speakerName}>Sinhae Lee</div>
                  <div className={styles.speakerPosition}>
                    Partner
                    <br />
                    of GBIC
                  </div>
                </div>
                <div className={styles.speakerCard2}>
                  <img className={styles.speakerImg} alt="Desci Seoul" src="/images/speakers-iann.png" />
                  <div className={styles.speakerName}>Iann de Lapeyriere</div>
                  <div className={styles.speakerPosition}>
                    Associate
                    <br />
                    of Saxon
                  </div>
                </div>
              </div>
              <img className={styles.speakersImg} alt="Desci Seoul" src="/images/mobile/speakers-img.png" />
            </section>
            <section className={styles.broughtSection_m} id="sponsors">
              <div className={styles.title}>
                BROUGHT TO
                <br />
                YOU BY
              </div>
              <div className={styles.logoWrapper}>
                <img alt="Desci Seoul" src="/images/mobile/brought-logo.png" />
              </div>
            </section>
            <section className={styles.supportersSection_m}>
              <div className={styles.title}>Partners</div>
              <div className={styles.logoWrapper} style={{ borderRadius: 16 }}>
                <img alt="Desci Seoul" src="/images/mobile/partners-logo.png" />
              </div>
            </section>
            <section className={styles.contactSection_m} id="contact">
              <div className={styles.contactWrapper}>
                <img className={styles.vectorIcon} alt="Desci Seoul" src="/images/mobile/contact-vector.svg" />
                <img className={styles.texture} alt="Desci Seoul" src="/images/mobile/contact-texture.png" />
                <img className={styles.texture2} alt="Desci Seoul" src="/images/mobile/contact-texture.png" />
                <div className={styles.title}>Contact US</div>
                <div className={styles.subTitle}>
                  Telegram
                  <img alt="Desci Seoul" src="/images/mobile/contact-arrow.svg" />
                </div>
                <img className={styles.qrIcon} alt="Desci Seoul" src="/images/qr.svg" />
                <div className={styles.buttonWrapper}>
                  <div className={styles.ticketBtn}>
                    <img alt="Desci Seoul" src="/images/mobile/contact-email.svg" />
                    <a href="mailto:desciseoul@gmail.com?subject=Desci Seoul." target="_blank">
                      <b>{`E-mail >`}</b>
                    </a>
                  </div>
                  <div className={styles.ticketBtn2}>
                    <img alt="Desci Seoul" src="/images/mobile/contact-x.svg" />
                    <a href="https://twitter.com/DesciSeoul" target="_blank">
                      <b>{`X(twitter) >`}</b>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.logoAnimationSection_m}>
              <div className={styles.logoAnimation}>
                <AutoPlayMobile />
              </div>
            </section>
            <section className={styles.registerSection_m}>
              <div className={styles.title}>
                Register
                <br />
                Now!
              </div>
              <div className={styles.buttonWrapper}>
                <div className={styles.ticketBtn}>
                  <img alt="Desci Seoul" src="/images/mobile/register-vector.svg" />
                  <a href="https://lu.ma/73ak22pr" target="_blank">
                    <b>{`get tickets >`}</b>
                  </a>
                </div>
              </div>
            </section>
            <section className={styles.footerSection_m}>
              <div className={styles.title}>ⓒ DeSci Seoul 2024. All Rights Reserved.</div>
            </section>
          </>
        )}
      </section>
    </section>
  );
};

export default HomePage;
