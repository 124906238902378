import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorPage, HomePage } from '../../pages';
import { DefaultLayout } from '../../layouts';

export const AppRouterProvider = () => {
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      errorElement: <ErrorPage />,
      Component: DefaultLayout,
      children: [
        {
          index: true,
          path: '/',
          Component: HomePage,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<></>} />;
};
